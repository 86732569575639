<template>
  <div id="account-setting-lottoset" class="container-fluid">

    <div class="page-header mb-1">
      <strong>ตั้งค่าหวยชุด</strong>
    </div>

    <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
      <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="market in avalaibleMarkets" :key="market._id" class="nav-item">
            <a class="nav-link" :class="[{'active':tabActiveMarketId===market._id}]" href="#" target="_self" @click="tabActiveMarketId=market._id">{{ market.marketTitle }}</a>
          </li>
        </ul>
      </div>

      <div class="p-2">
        <table class="table-accounts table table-hover table-bordered mb-0">
          <thead class="alert-warning">
            <tr>
              <th width="40" class="text-center" rowspan="3">ลำดับ</th>
              <th width="50" class="text-center" rowspan="2">เลือก</th>
              <th class="text-center" rowspan="3">
                ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th class="text-center" rowspan="3">ระดับ</th>
              <th class="text-center" rowspan="3">เปิด/ปิด</th>
              <th class="text-center" rowspan="2">ราคา<br />(บาท)</th>
              <th class="text-center" rowspan="2">คอมฯ<br />(บาท)</th>
              <!-- <th :colspan="marketOpenBets.length" class="text-center">จ่าย (บาท)</th> -->
              <th width="70" class="text-center" rowspan="2">แก้ไข</th>
            </tr>
            <tr>
              <!-- <th
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-center"
                v-if="isOpens[bet.code]"
              >{{ bet.text }}</th> -->
            </tr>
            <tr>
              <th class="text-center">
                <b-form-checkbox v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  :disabled="true"
                  v-model="inputBulkPrice"
                />
              </th>
              <th class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  :disabled="!bulkAccountIds.length"
                  v-model="inputBulkDiscount"
                />
              </th>
              <!-- <th
                v-for="bet in marketOpenBets"
                :key="bet.code"
                v-if="isOpens[bet.code]"
                class="p-1"
              >
                <b-form-input
                  size="sm"
                  :number="true"
                  :disabled="!bulkAccountIds.length"
                  v-model="inputBulk[bet.code]"
                />
              </th> -->
              <th class="text-center p-0">
                <b-button variant="success" size="sm" :disabled="!bulkAccountIds.length" @click="saveBulk">บันทึก</b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in accountItems" :key="account._id">
              <td class="text-center">{{ index+1 }}</td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="bulkAccountIds"
                  :value="account._id"
                  :disabled="!account.refAvailable"
                />
              </td>
              <td class="text-center">
                <div class="username">
                  <a v-if="account.role==='Agent'" href="#" @click="toggleChildAccount(account._id)">{{ account.username }}</a>
                  <span v-else>{{ account.username }}</span>
                </div>
                <small v-if="showName" class="text-secondary">({{ account.firstName }})</small>
              </td>
              <td class="text-center"><AccountRole :role="account.role" /></td>
              <td class="text-center" :class="[{'alert-dark': !account.refAvailable}]">
                <b-form-checkbox
                  v-model="account.isAvailable"
                  name="check-button"
                  switch
                  @change="toggleChange(account)"
                  :class="[account.isAvailable ? 'text-success' : 'text-danger']"
                  :disabled="!account.refAvailable"
                >
                  {{ account.isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td class="text-right" :class="[{'alert-dark': !account.isAvailable}]">{{ account.price | amountFormat(2, '0.00') }}</td>
              <td class="text-right" :class="[{'alert-dark': !account.isAvailable}]">{{ account.discount | amountFormat(2, '0.00') }}</td>
              <!-- <td
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-right"
                :class="[{'alert-dark': !account.isAvailable}]"
                v-if="isOpens[bet.code]"
              >
                <span v-if="account.isAvailable">{{account.prizes[bet.code] | amountFormat(2, '0.00')}}</span>
                <span v-else>-</span>
              </td> -->
              <td class="text-center" :class="[{'alert-dark': !account.isAvailable}]">
                <button :disabled="!account.isAvailable" class="btn btn-sm btn-outline-primary" @click="edit(account)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!accountItems.length">
              <td :colspan="marketOpenBets.length + 7" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <EditLottosetModal :is-show="isShowModal" :data="editData" :refMarket="refMarket" @close="modalClosed" />
  </div>
</template>
<script>
import AccountSettingService from '@/services/AccountSettingService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import { marketTypes } from '@/configs/market.config'
import EditLottosetModal from './components/EditLottosetModal'

export default {
  name: 'AccountSettingLottoset',
  components: {
    EditLottosetModal
  },
  data() {
    return {
      Breadcrumbs: [],
      accountId: this.$store.state.account._id,
      markets: [],
      tabActiveMarketId: null,
      accounts: [],
      refSetting: {},
      showName: false,
      isShowModal: false,
      editData: null,
      bulkAccountIds: [],
      checkAll: false,
      inputBulk: {},
      inputBulkPrice: null,
      inputBulkDiscount: null
    }
  },
  computed: {
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    market() {
      if(!this.tabActiveMarketId)
        return null

      return this.markets.find(market=>market._id===this.tabActiveMarketId)
    },
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((bet)=>{
        return this.market?.openBets?.[bet.code]
      })
    },
    isOpens() {
      return this.marketOpenBets.reduce((openBets, bet)=>{
        openBets[bet.code] = this.market?.openBets?.[bet.code] || false
        return openBets
      }, {})
    },
    accountItems() {
      if(this.market) {
        return this.accounts.map((acc)=>{
          return {
            _id: acc._id,
            username: acc.user.username,
            firstName: acc.user.firstName,
            role: acc.role,
            marketId: this.tabActiveMarketId,
            isAvailable: acc.markets[this.tabActiveMarketId].isAvailable,
            refAvailable: this.refSetting[this.tabActiveMarketId].isAvailable,
            price: acc.markets[this.tabActiveMarketId].price,
            discount: acc.markets[this.tabActiveMarketId].discount,
            prizes: acc.markets[this.tabActiveMarketId].prizes
          }
        })
      }else{
        return []
      }
    },
    refMarket() {
      return this.refSetting?.[this.tabActiveMarketId]
    },
    avalaibleMarkets() {
      return this.markets.filter((market)=>{
        return this.refSetting[market._id].isAvailable
      })
    }
  },
  watch: {
    checkAll(n, o) {
      this.bulkAccountIds = []
      if(n){
        this.bulkAccountIds = this.accountItems.filter(acc=>acc.isAvailable && acc.refAvailable).map(acc=>acc._id)
      }
    },
    tabActiveMarketId(n, o) {
      this.resetInput()
    }
  },
  methods: {
    loadAccounts() {
      AccountSettingService.getAccountSettingLottoset(this.accountId)
      .then((response) => {
        if(response.success) {
          this.accounts = response.data.accounts
          this.Breadcrumbs = response.data.parents
          this.markets = response.data?.markets || []
          this.refSetting = response.data.refSetting

          if(!this.tabActiveMarketId) {
            const openMarket = this.markets.find(m=>m.status === 'Open' && this.refSetting[m._id].isAvailable)
            if(openMarket) {
              this.tabActiveMarketId = openMarket._id
            }else
            if(this.markets.length > 0){
              this.tabActiveMarketId = this.markets[0]._id
            }
          }
        }
      })
    },
    toggleChildAccount(accountId) {
      this.accountId = accountId
      this.loadAccounts()
    },
    toggleChange(account) {
      this.save({
        accountIds: [account._id],
        marketId: account.marketId,
        isAvailable: account.isAvailable,
        price: account.price,
        discount: account.discount,
        prizes: account.prizes
      })
    },
    save(data) {
      AccountSettingService.updateAccountSettingLottoset(data)
      .then((response) => {
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
          this.resetInput()
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        console.log(e.message)
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadAccounts()
      })
    },
    edit(account) {
      this.editData = {
        ...account,
        market: this.market
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    saveBulk() {
      this.save({
        accountIds: this.bulkAccountIds,
        marketId: this.tabActiveMarketId,
        price: this.inputBulkPrice,
        discount: this.inputBulkDiscount,
        prizes: this.inputBulk
      })
    },
    resetInput() {
      this.checkAll = false
      this.inputBulk = {}
      this.inputBulkPrice = null
      this.inputBulkDiscount = null
    }
  },
  mounted() {
    this.loadAccounts()
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #FFF;
  border-radius: 10px;
}

.nav-link {
  cursor: pointer;
}

.table-accounts {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        text-align: right;
        line-height: 1;

        div.username {
          font-size: 110%;
        }
        small {
          font-size: 75%;
        }
      }
    }
  }
}

.limit-options {
  button {
    width: 150px;
  }
}
</style>
